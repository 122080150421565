'use client';
import React from 'react';
import { withReactSuspense } from '../../../hocs/withReactSuspense';
import { useGlobalTrackingScripts } from './index.hook';

export const GlobalTrackingScripts: React.FC<{
  TD_HOST: string;
  TD_DATABASE: string;
  TD_WRITEKEY: string;
}> = withReactSuspense((props) => {
  useGlobalTrackingScripts(props);

  return (
    <>
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
          !function(t,e){if(void 0===e[t]){e[t]=function(){e[t].clients.push(this),this._init=[Array.prototype.slice.call(arguments)]},e[t].clients=[];for(var r=["addRecord","blockEvents","fetchServerCookie","fetchGlobalID","fetchUserSegments","resetUUID","ready","setSignedMode","setAnonymousMode","set","trackEvent","trackPageview","trackClicks","unblockEvents"],s=0;s<r.length;s++){var c=r[s];e[t].prototype[c]=function(t){return function(){return this["_"+t]=this["_"+t]||[],this["_"+t].push(Array.prototype.slice.call(arguments)),this}}(c)}var n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=("https:"===document.location.protocol?"https:":"http:")+"//cdn.treasuredata.com/sdk/3.1/td.min.js";var o=document.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o)}}("Treasure",this);
          `,
        }}
      />
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WWD5MFG');
          `,
        }}
      />
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'pagelanding',
            });
          `,
        }}
      />
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            ;(function () {
              var tagjs = document.createElement("script");
              var s = document.getElementsByTagName("script")[0];
              tagjs.async = true;
              tagjs.src = "//s.yjtag.jp/tag.js#site=anJkO5c,FR5htDd,ELdcYpS";
              s.parentNode.insertBefore(tagjs, s);
            }());
          `,
        }}
      />
      <script defer src="https://sidecar.unext.jp/track/initiator" />
    </>
  );
});
