'use client';
import React from 'react';

/**
 * useSearchParamsを利用する場合のReact.Suspenseのラッパー
 * https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
 *
 * @param WrappedComponent
 * @returns
 */
export function withReactSuspense<P extends Record<string, unknown>>(
  WrappedComponent: React.FC<P>
) {
  const ComponentWithReactSuspense: React.FC<P> = (props) => (
    <React.Suspense>
      <WrappedComponent {...props} />
    </React.Suspense>
  );

  return ComponentWithReactSuspense;
}
