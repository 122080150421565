'use client';
import { useUserInfoAndMenus } from '../../../../hooks/useUserInfoAndMenus';

/**
 * 未ログインでは children を非表示にするコンポーネント。
 * 注意）初期化が完了するまで表示しません
 */
export const HideIfNotLoggedIn: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isLoadedUserInfo, userInfo } = useUserInfoAndMenus();

  return isLoadedUserInfo && userInfo.isLoggedIn ? <>{children}</> : null;
};
