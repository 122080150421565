'use client';
import { ApolloProvider } from '@apollo/client';
import { appPublicConfig } from '@/configs/appPublicConfig';
import { initApollo } from '@/shared/appRouter/libs/apollo/initApollo';
import { TapiContext } from '@/shared/hooks/useTapiFetcher';

const apolloClient = initApollo(appPublicConfig, 'stargate_help');

export const GlobalProviders: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <ApolloProvider client={apolloClient}>
    <TapiContext.Provider value={appPublicConfig.TAPI_ENDPOINT}>
      {children}
    </TapiContext.Provider>
  </ApolloProvider>
);
