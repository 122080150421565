'use client';
import { useLayoutState } from '../../_hooks/useLayoutState';

export const CloseButton: React.FC = () => {
  const { closeMobileMenu } = useLayoutState();

  return (
    <div
      className="tw-absolute tw-right-[1rem] tw-top-[1rem] tw-h-[1.5rem] tw-w-[2rem]"
      tabIndex={0}
      role="button"
      onClick={closeMobileMenu}
    >
      <div className="tw-absolute tw-inset-x-0 tw-top-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-translate-y-[0.57rem] tw-rotate-[-45deg] tw-bg-main-100" />
      <div className="tw-absolute tw-inset-x-0 tw-bottom-[2px] tw-m-auto tw-h-[2px] tw-w-full tw-translate-y-[-0.57rem] tw-rotate-45 tw-bg-main-100" />
    </div>
  );
};
