import { atom, useAtom } from 'jotai';
import React from 'react';
import UaParser from 'ua-parser-js';

type AccessDevice = {
  isLoaded: boolean;
  userAgent: string;
  isWebView: boolean;
  isIOS: boolean;
  isAndroid: boolean;
};

const accessDeviceAtom = atom<AccessDevice>({
  isLoaded: false,
  userAgent: '',
  isWebView: false,
  isIOS: false,
  isAndroid: false,
});

export function useAccessDevice() {
  const [accessDevice, setData] = useAtom(accessDeviceAtom);

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const uaParser = new UaParser(userAgent);

    setData({
      isLoaded: true,
      userAgent,
      isWebView: userAgent.toLowerCase().indexOf('u-next app') !== -1,
      isIOS: uaParser.getOS().name?.toLowerCase() === 'ios',
      isAndroid: uaParser.getOS().name?.toLowerCase() === 'android',
    });
  }, [setData]);

  return accessDevice;
}
