import { Spacer } from '../../parts/Spacer';

export const BasicContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div className="tw-custom-side-space tw-mx-auto tw-max-w-[64rem] tw-rounded-10 tw-border tw-border-main-10 tw-bg-base-100">
    <Spacer level={3} />
    <div className="tw-custom-side-space tw-mx-auto tw-max-w-[45rem]">
      {children}
    </div>
    <Spacer level={3} />
  </div>
);
