'use client';
import * as Sentry from '@sentry/nextjs';
import React from 'react';
import 'tailwind_config/styles/globals.css';
import 'tailwind_config/styles/custom.css';
import { appPublicConfig } from '@/configs/appPublicConfig';
import { CustomErrorPage } from '@/shared/appRouter/components/features/CustomErrorPage';

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="ja">
      <head>
        <title>U-NEXT</title>
      </head>
      <body>
        <CustomErrorPage config={appPublicConfig} statusCode={500} />
      </body>
    </html>
  );
}
