import { PlusWhiteIcon } from '../_icons/PlusWhiteIcon';
import type { ButtonProps } from '../types';

export const ButtonCaution: React.FC<
  ButtonProps & {
    prefixIcon?: 'plus';
  }
> = ({ text, width, prefixIcon = null, isLoading = false, ...attr }) => (
  <button
    type="button"
    className={
      width === 'fit'
        ? 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-fit tw-appearance-none tw-items-center tw-justify-center tw-rounded-8 tw-px-[1.5rem] tw-py-[0.75rem]'
        : 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-full tw-appearance-none tw-items-center tw-justify-center tw-rounded-8 tw-px-[1.5rem] tw-py-[0.75rem]'
    }
    {...attr}
  >
    <div className="tw-absolute tw-inset-0 tw-rounded-8 tw-bg-caution-primary tw-transition-[filter] group-hover:tw-brightness-110" />
    {isLoading && (
      <div className="tw-absolute tw-inset-y-0 tw-right-[0.5rem] tw-my-auto tw-h-[1rem] tw-w-[1rem]">
        <div className="tw-h-full tw-w-full tw-animate-spin tw-rounded-[50%] tw-border-[2px] tw-border-b-base-40 tw-border-l-base-100 tw-border-r-base-40 tw-border-t-base-100" />
      </div>
    )}
    <div className="tw-relative tw-flex tw-w-fit tw-items-center tw-gap-[0.25rem]">
      {prefixIcon === 'plus' && <PlusWhiteIcon />}
      <div className="tw-text-button tw-text-base-100">{text}</div>
    </div>
  </button>
);
