import { BasicContainer } from '../../composite/BasicContainer';
import { FormSubmitButtons } from '../../composite/FormSubmitButtons';
import { Spacer } from '../../parts/Spacer';
import { UnextLogoAndTypeDarkImage } from '../../parts/svgImages/UnextLogoAndTypeDarkImage';
import type { Config } from 'u-next/config';

function mapErrorMessage(statusCode: number) {
  const title =
    statusCode === 404
      ? 'お探しのページが見つかりません'
      : '予期せぬエラーが発生しました';

  const texts =
    statusCode === 404
      ? [
          '入力したアドレスが間違っているか、ページが移動した可能性があります。',
          `（${statusCode}）`,
        ]
      : [
          'サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。しばらく時間をおいてから、もう一度アクセスしてください。',
          `（${statusCode}）`,
        ];

  return {
    title,
    texts,
  };
}

export const CustomErrorPage: React.FC<{
  config: Config;
  statusCode: number;
}> = ({ config, statusCode }) => {
  const { title, texts } = mapErrorMessage(statusCode);
  return (
    <div>
      <Spacer level={4} />
      <div className="tw-flex tw-justify-center">
        <a href={config.VIDEO}>
          <UnextLogoAndTypeDarkImage alt="" className="tw-w-[10rem]" />
        </a>
      </div>
      <Spacer level={3} />
      <BasicContainer>
        <div className="tw-text-h3 tw-text-main-100">{title}</div>
        <Spacer level={1.5} />
        <div className="tw-text-base1 [&_a]:tw-text-accent-primary  [&_a]:tw-underline">
          {texts}
        </div>
        <Spacer level={2} />
        <FormSubmitButtons
          buttons={[
            {
              uiType: 'linkPrimary',
              href: config.VIDEO,
              text: 'ホーム',
            },
            {
              uiType: 'linkSecondary',
              href: `${config.MYACCOUNT}/unext-point`,
              text: 'U-NEXTポイント',
            },
            {
              uiType: 'linkSecondary',
              href: `${config.MYACCOUNT}/settings`,
              text: 'アカウント・契約',
            },
            {
              uiType: 'linkSecondary',
              href: config.HELP,
              text: 'ヘルプセンター',
            },
          ]}
        />
      </BasicContainer>
    </div>
  );
};
