'use client';
import { useAccessDevice } from '../../../hooks/useAccessDevice';

/**
 * アプリ内ブラウザでは children を非表示にするコンポーネント。
 * 注意）初期状態は表示してしまいます
 */
export const HideInAppBrowser: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isWebView } = useAccessDevice();

  return !isWebView ? <>{children}</> : null;
};
