'use client';
import { useUserInfoAndMenus } from '../../../../../../hooks/useUserInfoAndMenus';
import { UnextLogoAndTypeDarkImage } from '../../../../parts/svgImages/UnextLogoAndTypeDarkImage';
import type { UserInfo } from '../../../../../../hooks/useUserInfoAndMenus';
import type { Config } from 'u-next/config';

function extractLogoImage(userInfo: UserInfo): React.ReactNode {
  if (userInfo.isYamadaPF) {
    return (
      <img
        className="tw-block tw-h-full tw-w-full tw-object-contain tw-object-center"
        src="//metac.nxtv.jp/img/wf/logo/yamada.png"
        alt="U-NEXT"
      />
    );
  }

  switch (userInfo.otherFunctionId) {
    case '600':
    case '700':
    case '701':
      return (
        <img
          className="tw-block tw-h-full tw-w-full tw-object-contain tw-object-center"
          src={`//metac.nxtv.jp/img/wf/logo/unext${userInfo.otherFunctionId}.png`}
          alt="U-NEXT"
        />
      );

    default:
      return (
        <UnextLogoAndTypeDarkImage
          className="tw-absolute tw-inset-y-0 tw-my-auto tw-block tw-w-[8rem]"
          alt="U-NEXT"
        />
      );
  }
}

export const SiteLogo: React.FC<{
  options: Config;
}> = ({ options }) => {
  const { userInfo } = useUserInfoAndMenus();
  return (
    <a
      href={options.VIDEO}
      className="tw-absolute tw-inset-y-0 tw-left-[1rem] tw-w-[10rem]"
    >
      {extractLogoImage(userInfo)}
    </a>
  );
};
