import { usePathname } from 'next/navigation';
import React from 'react';
import { useUserInfoAndMenus } from '../../../../hooks/useUserInfoAndMenus';
import type { CustomWindow, TDConfig } from 'u-next/window';

function sendGoogleAnalyticsEvent() {
  if (!(window as CustomWindow).dataLayer) {
    return;
  }

  (window as CustomWindow).dataLayer.push({
    event: 'pagelanding',
  });
}

export const useGlobalTrackingScripts = (props: {
  TD_HOST: string;
  TD_DATABASE: string;
  TD_WRITEKEY: string;
}) => {
  const pathname = usePathname();

  const { isLoadedUserInfo, userInfo } = useUserInfoAndMenus();

  const sendTreasurePageTransitionLog = React.useCallback(() => {
    if (!(window as CustomWindow).Treasure) {
      return;
    }

    const tdParams: TDConfig = {
      host: props.TD_HOST,
      database: props.TD_DATABASE,
      writeKey: props.TD_WRITEKEY,
    };

    const td = new (window as CustomWindow).Treasure(tdParams);

    td.setSignedMode();
    td.set('$global', 'td_global_id', 'td_global_id');
    td.set('page_transition_log', {
      user_platform_id: userInfo.userPlatformId,
      user_multi_account_id: userInfo.userMultiAccountId,
    });
    td.fetchGlobalID();
    td.trackPageview('page_transition_log');
  }, [
    props.TD_DATABASE,
    props.TD_HOST,
    props.TD_WRITEKEY,
    userInfo.userMultiAccountId,
    userInfo.userPlatformId,
  ]);

  React.useEffect(() => {
    if (!isLoadedUserInfo) {
      return;
    }

    sendTreasurePageTransitionLog();
    sendGoogleAnalyticsEvent();
  }, [isLoadedUserInfo, pathname, sendTreasurePageTransitionLog]);

  React.useEffect(() => {
    if (!isLoadedUserInfo) {
      return;
    }

    (window as CustomWindow).unx = {
      usrinfo: {
        isLogin: userInfo.isLoggedIn,
        userPlatformId: userInfo.userPlatformId,
        multiAccountId: userInfo.userMultiAccountId,
      },
    };
  }, [
    isLoadedUserInfo,
    userInfo.isLoggedIn,
    userInfo.userMultiAccountId,
    userInfo.userPlatformId,
  ]);

  return {};
};
