import { ButtonCaution } from '../../parts/buttons/ButtonCaution';
import { ButtonLinkPrimary } from '../../parts/buttons/ButtonLinkPrimary';
import { ButtonLinkSecondary } from '../../parts/buttons/ButtonLinkSecondary';
import { ButtonPrimary } from '../../parts/buttons/ButtonPrimary';
import { ButtonSecondary } from '../../parts/buttons/ButtonSecondary';

type ButtonPrimaryProps = Omit<
  React.ComponentProps<typeof ButtonPrimary>,
  'width'
> & {
  uiType: 'buttonPrimary';
};

type ButtonSecondaryProps = Omit<
  React.ComponentProps<typeof ButtonSecondary>,
  'width'
> & {
  uiType: 'buttonSecondary';
};

type ButtonCautionProps = Omit<
  React.ComponentProps<typeof ButtonCaution>,
  'width'
> & {
  uiType: 'buttonCaution';
};

type LinkPrimaryProps = Omit<
  React.ComponentProps<typeof ButtonLinkPrimary>,
  'width'
> & {
  uiType: 'linkPrimary';
};

type LinkSecondaryProps = Omit<
  React.ComponentProps<typeof ButtonLinkSecondary>,
  'width'
> & {
  uiType: 'linkSecondary';
};

type ButtonProps =
  | ButtonPrimaryProps
  | ButtonSecondaryProps
  | ButtonCautionProps
  | LinkPrimaryProps
  | LinkSecondaryProps;

export const FormSubmitButtons: React.FC<{
  buttons: ButtonProps[];
}> = ({ buttons }) => (
  <div className="tw-mx-auto tw-grid tw-w-full tw-max-w-[20rem] tw-grid-cols-1 tw-justify-items-center tw-gap-[1rem]">
    {buttons.map((props, index) => (
      <div key={index} className="tw-w-full">
        <Button {...props} />
      </div>
    ))}
  </div>
);

function isButtonPrimary(props: ButtonProps): props is ButtonPrimaryProps {
  return props.uiType === 'buttonPrimary';
}

function isButtonSecondary(props: ButtonProps): props is ButtonSecondaryProps {
  return props.uiType === 'buttonSecondary';
}

function isButtonCaution(props: ButtonProps): props is ButtonCautionProps {
  return props.uiType === 'buttonCaution';
}

function isLinkPrimary(props: ButtonProps): props is LinkPrimaryProps {
  return props.uiType === 'linkPrimary';
}

function isLinkSecondary(props: ButtonProps): props is LinkSecondaryProps {
  return props.uiType === 'linkSecondary';
}

const Button: React.FC<ButtonProps> = (props) => {
  if (isButtonPrimary(props)) {
    const { uiType: _, ...attr } = props;
    return <ButtonPrimary {...attr} width="full" />;
  }

  if (isButtonSecondary(props)) {
    const { uiType: _, ...attr } = props;
    return <ButtonSecondary {...attr} width="full" />;
  }

  if (isButtonCaution(props)) {
    const { uiType: _, ...attr } = props;
    return <ButtonCaution {...attr} width="full" />;
  }

  if (isLinkPrimary(props)) {
    const { uiType: _, ...attr } = props;
    return <ButtonLinkPrimary {...attr} width="full" />;
  }

  if (isLinkSecondary(props)) {
    const { uiType: _, ...attr } = props;
    return <ButtonLinkSecondary {...attr} width="full" />;
  }
};
