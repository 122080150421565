import Link from 'next/link';
import { PlusWhiteIcon } from '../_icons/PlusWhiteIcon';
import type { ButtonLinkProps } from '../types';

export const ButtonLinkPrimary: React.FC<
  ButtonLinkProps & {
    prefixIcon?: 'plus';
  }
> = ({ text, href, width, prefixIcon = null, ...attr }) => (
  <Link
    {...attr}
    href={href}
    passHref
    className={
      width === 'fit'
        ? 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-fit tw-items-center tw-justify-center tw-rounded-8 tw-px-[1.5rem] tw-py-[0.75rem]'
        : 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-full tw-items-center tw-justify-center tw-rounded-8 tw-px-[1.5rem] tw-py-[0.75rem]'
    }
  >
    <div className="tw-absolute tw-inset-0 tw-rounded-8 tw-bg-accent-primary tw-transition-[filter] hover:tw-brightness-110" />
    <div className="tw-relative tw-flex tw-w-fit tw-items-center tw-gap-[0.25rem]">
      {prefixIcon === 'plus' && <PlusWhiteIcon />}
      <div className="tw-text-button tw-text-base-100">{text}</div>
    </div>
  </Link>
);
