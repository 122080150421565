'use client';
import { usePathname } from 'next/navigation';
import React from 'react';
import { createPortal } from 'react-dom';
import { useLayoutState } from '../../_hooks/useLayoutState';

export const VisibilitySideNavigation: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const pathname = usePathname();
  const { isMobileMenuOpen, closeMobileMenu } = useLayoutState();

  React.useEffect(() => {
    // pathnameが変わった = 何か遷移したと思うので、メニューを閉じておく
    closeMobileMenu();
  }, [closeMobileMenu, pathname]);

  return isMobileMenuOpen
    ? createPortal(<>{children}</>, document.getElementById('modal') as Element)
    : null;
};
