import { atom, useAtom } from 'jotai';
import React from 'react';

const isMobileMenuOpenAtom = atom(false);

export function useLayoutState() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useAtom(isMobileMenuOpenAtom);

  const openMobileMenu = React.useCallback(() => {
    setIsMobileMenuOpen(true);
  }, [setIsMobileMenuOpen]);

  const closeMobileMenu = React.useCallback(() => {
    setIsMobileMenuOpen(false);
  }, [setIsMobileMenuOpen]);

  return {
    isMobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
  };
}
