export const Spacer: React.FC<{
  level: 4 | 3 | 2 | 1.5 | 1 | 0.5 | 0.25;
}> = ({ level }) => (
  <div
    className={
      level === 4
        ? 'tw-h-[4rem]'
        : level === 3
          ? 'tw-h-[3rem]'
          : level === 2
            ? 'tw-h-[2rem]'
            : level === 1.5
              ? 'tw-h-[1.5rem]'
              : level === 1
                ? 'tw-h-[1rem]'
                : level === 0.5
                  ? 'tw-h-[0.5rem]'
                  : level === 0.25
                    ? 'tw-h-[0.25rem]'
                    : ''
    }
  />
);
