'use client';
import { useLayoutState } from '../../_hooks/useLayoutState';

export const BackgroundFilter: React.FC = () => {
  const { closeMobileMenu } = useLayoutState();

  return (
    <div
      className="tw-absolute tw-inset-0 tw-bg-main-40"
      tabIndex={0}
      role="button"
      onClick={closeMobileMenu}
    />
  );
};
